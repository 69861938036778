import Box from "@mui/material/Box";
import Dashboard from "../dashboard/Dashboard";
import DataGridTodayVisitorsReportSendEmail from "../../components/dataGrid/ReportDataGridTodayVisitorsSendEmail";


function TodayVisitorsPage() {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Dashboard />
				<DataGridTodayVisitorsReportSendEmail />
			</Box>
		</>
	);
}

export default TodayVisitorsPage;

