
import DataGridUsersEditable from "./dataGrid/DataGridUsersEditable";
import Dashboard from "../pages/dashboard/Dashboard";
import Box from "@mui/material/Box";

function UsersDatabase() {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Dashboard />
				<DataGridUsersEditable />
			</Box>
		</>
	);
}

export default UsersDatabase;