import React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";
import { Typography } from "@mui/material";
import Title from "../../pages/dashboard/Title";

const LineChartMonthlySummary = ({ data }) => {
	const theme = useTheme();

	// Map month names to their order
	const monthOrder = {
		"January": 1,
		"February": 2,
		"March": 3,
		"April": 4,
		"May": 5,
		"June": 6,
		"July": 7,
		"August": 8,
		"September": 9,
		"October": 10,
		"November": 11,
		"December": 12,
	};

	// Clean and sort the data by month
	const dataConverted = data
		.map((obj) => ({
			...obj,
			month_name: obj.month_name.trim(), // Trim the month_name to remove extra spaces
			total_visits: parseInt(obj.total_visits, 10),
			total_adults: parseInt(obj.total_adults, 10),
			total_children: parseInt(obj.total_children, 10),
			monthOrder: monthOrder[obj.month_name.trim()], // Add month order to the object
		}))
		.sort((a, b) => a.monthOrder - b.monthOrder); // Sort based on the month order

	// Log the sorted data to verify the order
	console.log("Sorted Data:", dataConverted.map((item) => item.month_name));

	return (
		<React.Fragment>
			<Title>Household per month {new Date().getFullYear()} </Title>
			{dataConverted ? (
				<div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
					<LineChart
						xAxis={[
							{
								scaleType: "point",
								data: dataConverted.map((item) => item.month_name), // Explicitly set x-axis data
								label: "Months",
								tickLabelStyle: theme.typography.body2,
							},
						]}
						yAxis={[
							{
								label: "Household",
								labelStyle: {
									...theme.typography.body1,
									fill: theme.palette.text.primary,
								},
								tickLabelStyle: theme.typography.body2,
								max: 150,
								min: 0,
								tickNumber: 6,
							},
						]}
						series={[
							{
								data: dataConverted.map((item) => item.total_visits),
								label: "Monthly Total Visits",
								color: theme.palette.primary.light,
								showMark: true,
								area: true,
							},
						]}
						height={250}
						sx={{
							[`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
							[`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
							[`& .${axisClasses.left} .${axisClasses.label}`]: {
								transform: "translateX(-25px)",
							},
						}}
					/>
				</div>
			) : (
				<p>Loading...</p>
			)}
		</React.Fragment>
	);
};

export default LineChartMonthlySummary;
