import { Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import DoNotHavePermission from "./pages/DoNotHavePermission/DoNotHavePermission";
import UsersDatabase from "./components/UsersDatabase";
import { AdminElement, AssistantElement }  from "./Context/ElementsLayout";
import RegisterVisitors from "./components/RegisterVisitors";
import WeeklyVisitorsReport from "./components/WeeklyVisitorsReport";
import { AuthContextProvider } from "./Context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import TodayVisitorsPage from "./pages/todayVisitors/TodayVisitorsPage";

const USER_TYPE = {
	PUBLIC: "Public User",
	ASSISTANT: "Assistant User",
	ADMIN: "Admin User",
};

const CURRENT_USER_TYPE = USER_TYPE.ADMIN;

const App = () => (
	<AuthContextProvider>
		<Routes>
			<Route path="*" element={<NotFound />} />
			<Route path="/do-not-have-permission" element={<DoNotHavePermission />} />
			<Route path="/login" element={<Login />} />
			<Route path="/about/this/site" element={<About />} />
			<Route path="/" element={<ProtectedRoute><AdminElement currentUser={CURRENT_USER_TYPE}><Home /></AdminElement></ProtectedRoute>} />
			<Route path="/user-database" element={<ProtectedRoute><AdminElement currentUser={CURRENT_USER_TYPE}><UsersDatabase /></AdminElement></ProtectedRoute>} />
			<Route path="/user-visitors" element={<ProtectedRoute><AdminElement currentUser={CURRENT_USER_TYPE}><RegisterVisitors /></AdminElement></ProtectedRoute>} />
			<Route path="/dashboard" element={<ProtectedRoute><AdminElement currentUser={CURRENT_USER_TYPE}><Dashboard /></AdminElement></ProtectedRoute>} />
			<Route path="/weekly-visitors" element={<ProtectedRoute><AdminElement currentUser={CURRENT_USER_TYPE}><WeeklyVisitorsReport /></AdminElement></ProtectedRoute>} />
			<Route path="/today-visitors" element={<ProtectedRoute><AdminElement currentUser={CURRENT_USER_TYPE}><TodayVisitorsPage /></AdminElement></ProtectedRoute>} />
		</Routes>
	</AuthContextProvider>
);

export default App;
