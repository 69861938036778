import Dashboard from "../pages/dashboard/Dashboard";
import Box  from "@mui/material/Box";
import DataGridWeeklyUsers from "./dataGrid/DataGridWeeklyUsers";


function WeeklyVisitorsReport() {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Dashboard />
				<DataGridWeeklyUsers />
			</Box>
		</>
	);
}

export default WeeklyVisitorsReport;