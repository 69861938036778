// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCIrzK_wU9OZfyuMdg-Fj8yGFVSqcCiwYE",
	authDomain: "auldhouse-cc.firebaseapp.com",
	projectId: "auldhouse-cc",
	storageBucket: "auldhouse-cc.appspot.com",
	messagingSenderId: "78499210611",
	appId: "1:78499210611:web:05a4fe58e5dbca8deb9106",
	measurementId: "G-T9J40JC1CZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;