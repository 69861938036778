import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import Title from "../../pages/dashboard/Title";

const chartSetting = {
	yAxis: [
		{
			label: "" ,
		},
	],
	height: 250,
	sx: {
		[`.${axisClasses.left} .${axisClasses.label}`]: {
			transform: "translate(-10px, 0)",
		},
	},
};

const valueFormatter = (value) => `${value}`;

const monthOrder = {
	"January": 1,
	"February": 2,
	"March": 3,
	"April": 4,
	"May": 5,
	"June": 6,
	"July": 7,
	"August": 8,
	"September": 9,
	"October": 10,
	"November": 11,
	"December": 12,
};

export default function BarChartMonthlySummaryDetails({ data }) {
	const [dataGroup, setDataGroup] = useState([]);
	const [isLoading, setIsLoading] = useState(true);



	useEffect(() => {
		if (data && Array.isArray(data)) {
			setDataGroup(
				data.map((obj) => ({
					...obj,
					month_name: obj.month_name.trim(), // Trim the month_name to remove extra spaces
					total_visits: parseInt(obj.total_visits, 10),
					total_adults: parseInt(obj.total_adults, 10),
					total_children: parseInt(obj.total_children, 10),
					monthOrder: monthOrder[obj.month_name.trim()], // Add month order to the object
				}))
					.sort((a, b) => a.monthOrder - b.monthOrder) // Sort based on the month order
			);
			setIsLoading(false);
		}

	}, [data]);

	return (
		<>
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					{dataGroup.length > 0 && (
						<>
							<Title>Monthly household breakdown  {new Date().getFullYear()}</Title>
							<BarChart
								dataset={dataGroup}
								xAxis={[{ scaleType: "band", dataKey: "month_name" }]}
								series={[
									{ dataKey: "total_visits", label: "Household", valueFormatter, color: "#2ca02c" },
									{ dataKey: "total_adults",
										label: "Adults",
										valueFormatter,
										color: "#ff7f0e" },
									{
										dataKey: "total_children",
										label: "Children",
										valueFormatter,
										color: "#1f77b4",
									},
								]}
								{...chartSetting}
							/>
						</>
					)}
					{!dataGroup.length && <div>No data available</div>}
				</>
			)}
		</>
	);
}
