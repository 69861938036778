import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function emergency(props) {
	return (
		<Dialog open={props.open} onClose={props.handleCloseSaveEmergency}>
			<DialogTitle>Comments box</DialogTitle>
			<DialogContent>
				<DialogContentText>
          Please write a short explanation about this emergency allocation.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="This allocations is due to"
					type="text"
					fullWidth
					variant="standard"
					value={props.comments}
					onChange={props.handleChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleCancelClose}>Cancel</Button>
				<Button onClick={props.handleCloseSaveEmergency}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}
