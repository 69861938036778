import DataGridLog from "./dataGrid/DataGridLog";
import Box from "@mui/material/Box";
import Dashboard from "../pages/dashboard/Dashboard";

function RegisterVisitors() {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Dashboard />
				<DataGridLog />
			</Box>
		</>
	);
}

export default RegisterVisitors;
