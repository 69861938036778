import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";7;


function NotFound() {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "100vh",
			}}
		>
			<Container maxWidth="md">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>

						<Typography variant="h1">
              Hi there,
						</Typography>
						<Typography variant="h6" mb={2} >
              The page you’re looking for doesn’t exist.
						</Typography>
						<Button variant="contained" size="large"><Link  to="/login">Login</Link></Button>

					</Grid>
					<Grid item xs={12} sm={6}>
						<img
							src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
							alt=""
							width={500} height={250}
						/>

					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default NotFound;