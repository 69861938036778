import * as React from "react";
import {
	DataGrid,
	GridToolbarQuickFilter,
	GridLogicOperator,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

const columns = [
	{ field: "user_id", headerName: "id", width: 120, hideable: false },
	{ field: "first_name", headerName: "First Name", width: 120, editable: true },
	{ field: "last_name", headerName: "Last Name", width: 120, editable: true },
	{
		field: "phone_number",
		headerName: "Phone Number",
		width: 140,
		editable: true,
	},
	{ field: "email", headerName: "Email", width: 190, editable: true },
	{
		field: "address_line_1",
		headerName: "Address Line 1",
		width: 150,
		editable: true,
	},
	{
		field: "postal_code",
		headerName: "Postal Code",
		width: 120,
		editable: true,
	},
	{
		field: "visiting_cycle_id",
		headerName: "Visiting Cycle",
		width: 120,
		editable: true,
	},
	{
		field: "dependant_count",
		headerName: "Dependants",
		width: 120,
		editable: true,
	},
	{ field: "adults_count", headerName: "Adults", width: 120, editable: true },
	{
		field: "children_count",
		headerName: "Children Count",
		width: 140,
		editable: true,
	},
	{
		field: "dob",
		headerName: "DOB",
		type: "date",
		width: 120,
		editable: true,
		valueGetter: (params) => (params.value ? new Date(params.value) : null),
		valueSetter: (params) => {
			const date = new Date(params.value);
			const localDate = new Date(
				date.getTime() - date.getTimezoneOffset() * 60000
			)
				.toISOString()
				.split("T")[0];
			return { ...params.row, dob: localDate };
		},
	},

	{
		field: "in_case_of_emergency_phone",
		headerName: "Emergency Phone",
		width: 160,
		editable: true,
	},
	{
		field: "in_case_of_emergency_name",
		headerName: "Emergency Name",
		width: 160,
		editable: true,
	},
];

function computeMutation(newRow, oldRow) {
	const changes = [];
	for (const key in newRow) {
		if (newRow[key] !== oldRow[key]) {
			changes.push(`${key} from '${oldRow[key]}' to '${newRow[key]}'`);
		}
	}
	return changes.length ? changes.join(", ") : null;
}

function QuickSearchToolbar() {
	return (
		<Box
			sx={{
				p: 2,
			}}
		>
			<GridToolbarQuickFilter
				quickFilterParser={(searchInput) =>
					searchInput
						.split(",")
						.map((value) => value.trim())
						.filter((value) => value !== "")
				}
			/>
		</Box>
	);
}
export default function AskConfirmationBeforeSave() {
	const noButtonRef = React.useRef(null);
	const [promiseArguments, setPromiseArguments] = React.useState(null);
	const [rows, setRows] = React.useState([]);
	const [snackbar, setSnackbar] = React.useState(null);
	const handleCloseSnackbar = () => setSnackbar(null);

	React.useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		try {
			const response = await fetch("/api/database-users");
			if (!response.ok) {
				throw new Error("Failed to fetch users");
			}
			const data = await response.json();
			setRows(data);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	const processRowUpdate = React.useCallback(
		(newRow, oldRow) =>
			new Promise((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				if (mutation) {
					// Save the arguments to resolve or reject the promise later
					setPromiseArguments({ resolve, reject, newRow, oldRow });
				} else {
					resolve(oldRow); // Nothing was changed
				}
			}),
		[]
	);

	const handleNo = () => {
		const { oldRow, resolve } = promiseArguments;
		resolve(oldRow); // Resolve with the old row to not update the internal state
		setPromiseArguments(null);
	};

	const handleYes = async () => {
		const { newRow, oldRow, reject, resolve } = promiseArguments;

		try {

			const response = await fetch(`/api/update-users/${newRow.user_id}`, {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(newRow),
			});
			if (!response.ok) {
				throw new Error("Failed to update user");
			}
			const result = await response.json();
			setSnackbar({ children: "User successfully saved", severity: "success" });

			// Update the rows state
			setRows((prevRows) =>
				prevRows.map((row) => (row.user_id === newRow.user_id ? newRow : row))
			);

			resolve(result);
		} catch (error) {
			console.error("Error updating user:", error);
			setSnackbar({ children: "Failed to save user", severity: "error" });
			reject(oldRow);
		} finally {
			setPromiseArguments(null);
		}
	};

	const handleEntered = () => {
		noButtonRef.current?.focus();
	};

	const renderConfirmDialog = () => {
		if (!promiseArguments) {
			return null;
		}

		const { newRow, oldRow } = promiseArguments;
		const mutation = computeMutation(newRow, oldRow);

		return (
			<Dialog
				maxWidth="xs"
				TransitionProps={{ onEntered: handleEntered }}
				open={!!promiseArguments}
			>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent dividers>
					{`Pressing 'Yes' will change ${mutation}.`}
				</DialogContent>
				<DialogActions>
					<Button ref={noButtonRef} onClick={handleNo}>
            No
					</Button>
					<Button onClick={handleYes}>Yes</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<div style={{ width: "80%" }}>
			<div style={{ height: 350, width: "100%" }}>
				<Box
					sx={{
						mt: 10,
						ml: 2,
						height: "550px",
						overflow: "auto",
						"& .actions": { color: "text.secondary" },
						"& .textPrimary": { color: "text.primary" },
					}}
				>
					{renderConfirmDialog()}
					<DataGrid
						getRowId={(row) => row.user_id}
						rows={rows}
						columns={columns}
						processRowUpdate={processRowUpdate}
						slots={{ toolbar: QuickSearchToolbar }}
						initialState={{
							...rows.initialState,
							columns: {
								...columns.initialState?.columns,
								columnVisibilityModel: {
									user_id: false,
								},
							},
							filter: {
								...rows.initialState?.filter,
								filterModel: {
									items: [],
									quickFilterLogicOperator: GridLogicOperator.Or,
								},
							},
						}}
					/>
					{!!snackbar && (
						<Snackbar
							open
							onClose={handleCloseSnackbar}
							autoHideDuration={6000}
						>
							<Alert {...snackbar} onClose={handleCloseSnackbar} />
						</Snackbar>
					)}
				</Box>
			</div>
		</div>
	);
}