import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { ExportButton } from "../../components/excel-export/ExportButton";
import Box from "@mui/material/Box";

function CustomToolbar(props) {
	return (
		<GridToolbarContainer {...props}>
			<ExportButton />
		</GridToolbarContainer>
	);
}

export default function ExportCustomToolbar() {
	const [dataSet, setDataSet] = useState([]);
	const url = "/api/weekly_logs_of_users";

	const getUsers = () => {
		fetch(url)
			.then((resp) => resp.json())
			.then((resp) => setDataSet(resp));
	};

	useEffect(() => {
		getUsers();
	}, []);

	const data = {
		columns: [
			{ field: "current_year", headerName: "Year", width: 55 },
			{ field: "current_month", headerName: "Month", width: 90 },
			{ field: "week_day", headerName: "Date", width: 110 },
			{ field: "household", headerName: "Household", width: 90 },
			{ field: "total_dependants", headerName: "dependant total", width: 120 },
			{ field: "total_adults", headerName: "Adult total", width: 85 },
			{ field: "total_children", headerName: "Children total", width: 85 },
		],
		rows: dataSet,
		rowLength: 50,
	};

	return (
		<div style={{ width: "80%" }}>
			<div style={{ height: 350, width: "100%" }}>
				<Box
					sx={{
						mt: 10,
						ml: 2,
						height: "550px",
						overflow: "auto",
						"& .actions": {
							color: "text.secondary",
						},
						"& .textPrimary": {
							color: "text.primary",
						},
					}}
				>
					<DataGrid
						{...data}
						getRowId={(row) => row.week_day}
						columns={data.columns}
						row={data.rows}
						initialState={{
							sorting: {
								sortModel: [{ field: "current_year", sort: "desc" }],
							},
						}}
						density="standard"
						slots={{ toolbar: CustomToolbar }}
					/>
				</Box>
			</div>
		</div>
	);
}
