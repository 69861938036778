const config = {
	columnNames: [
		"Year",
		"Month",
		"Date",
		"Household",
		"Dependant total",
		"Adult total",
		"Children total",
	],
	keys: ["current_year",
		"current_month",
		"week_day",
		"household",
		"total_dependants",
		"total_adults",
		"total_children" ],
	fileName: "data.xlsx",
	sheetName: "Auldhouse info",
};

export { config };
