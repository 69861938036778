import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Title from "../../pages/dashboard/Title";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LineChartMonthlySummary from "./LineChartMonthlySummary";
import BarChartMonthlySummaryDetails from "./BarChartMonthlySummaryDetails";


const weeklyUserCountUrl = "/api/weekly-visitor-count";
const monthlyUsersCountUrl = "/api/monthly-count-of-users";
const monthlyHouseholdsCountUrl = "/api/monthly-count-of-households";
const chartMonthlyVisitorsUrl = "api/parameterize-monthly-sum";

export default function Charts() {
	const [weekData, setWeekData] = useState([]);
	const [monthlyCountData, setMonthlyCountData] = useState([]);
	const [monthlyHouseholdCountData, setMonthlyHouseholdCountData] = useState([]);
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		getWeeklyUsers();
		getChartData();
		getCountHousehold();
	}, []);

	const getWeeklyUsers = () => {
		fetch(weeklyUserCountUrl)
			.then((resp) => resp.json())
			.then((resp) => setWeekData(resp));
	};

	const getCountUsers = () => {
		fetch(monthlyUsersCountUrl)
			.then((resp) => resp.json())
			.then((resp) => setMonthlyCountData(resp));
	};

	const getCountHousehold = () => {
		fetch(monthlyHouseholdsCountUrl)
			.then((resp) => resp.json())
			.then((resp) => setMonthlyHouseholdCountData(resp));
	};

	const getChartData = () => {
		try {
			fetch(chartMonthlyVisitorsUrl)
				.then((resp) => resp.json())
				.then((resp) => setChartData(resp));
		} catch (error) {
			console.log(error);
		}
	};

	const dataHousehold = weekData.map((e) => e.household);
	const dataAdults = weekData.map((e) => e.adults);
	const dataChildren = weekData.map((e) => e.children);
	const monthlyCount = monthlyCountData.map((e) => e.count);
	const todayDate = new Date();
	const totalDependants = monthlyHouseholdCountData.map((e) => e.total_count);
	const totalAdults = monthlyHouseholdCountData.map((e) => e.total_adults);
	const totalChildren = monthlyHouseholdCountData.map((e) => e.total_children);



	return (
		<Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4} lg={4	}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<React.Fragment>
							<Title> Monthly visitors</Title>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Box sx={{ pr: 1.9, py: 1 }}>
									<Typography component="p" variant="h6" color="text.info">Household</Typography>
									<Typography component="p" variant="h4">
										{totalDependants > 0 ? totalDependants : 0}
									</Typography>
								</Box>
								<Box sx={{ pr: 1.9, py: 1 }}>
									<Typography component="p" variant="h6" color="text.info">Adults</Typography>
									<Typography component="p" variant="h4">
										{totalAdults > 0 ? totalAdults : 0}
									</Typography>
								</Box>
								<Box sx={{ pr: 1.9, py: 1 }}>
									<Typography component="p" variant="h6" color="text.info">Children</Typography>
									<Typography component="p" variant="h4">
										{totalChildren > 0 ? totalChildren : 0}
									</Typography>
								</Box>
							</Box>
							<Typography variant= "h6" color="text.secondary" sx={{ flex: 1 }}>
								{todayDate.toLocaleString("en-GB", {
									month: "long",
									year: "numeric",
								})}
							</Typography>
						</React.Fragment>
					</Paper>
				</Grid>

				<Grid item xs={12} md={4} lg={4}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<React.Fragment>
							<Title>
                Today's household visitors
							</Title>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Box sx={{ pr: 1.9, py: 1 }}>
									<Typography component="p" variant="h6" color="text.info">Household</Typography>
									<Typography component="p" variant="h4">
										{dataHousehold > 0 ? dataHousehold : 0}
									</Typography>
								</Box>
								<Box sx={{ pr: 1.9, py: 1 }}>
									<Typography component="p" variant="h6" color="text.info">Adults</Typography>
									<Typography component="p" variant="h4">
										{dataAdults > 0 ? dataAdults : 0}
									</Typography>
								</Box>
								<Box sx={{ pr: 1.9, py: 1 }}>
									<Typography component="p" variant="h6" color="text.info">Children</Typography>
									<Typography component="p" variant="h4">
										{dataChildren > 0 ? dataChildren : 0}
									</Typography>
								</Box>
							</Box>
							<Typography  variant= "h6" color="text.secondary" sx={{ flex: 1 }}>
								{todayDate.toLocaleString("en-GB", {
									month: "long",
									day: "numeric",
									year: "numeric",
								})}
							</Typography>
						</React.Fragment>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={10}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<React.Fragment>
							<LineChartMonthlySummary data={chartData} />
						</React.Fragment>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={10}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<React.Fragment>
							<BarChartMonthlySummaryDetails data={chartData} />
						</React.Fragment>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
}
