import ReportDataGrid from "../../components/dataGrid/ReportDataGrid";


function DataGridWeeklyUsers() {

	return (
		<ReportDataGrid />
	);
}
export default DataGridWeeklyUsers;
