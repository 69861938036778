import React, { useState, useEffect } from "react";
import DataGridWeekVisitorsReports from "./DataGridWeekVisitorsReports";
import Box from "@mui/material/Box";

function formatDateToYYYYMMDD(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}

const url = "/api/parameterize-today-data";
const urlSendMail = "/api/send-email-template";

const fetchUserData = async () => {
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error("Network response was not ok.");
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching data:", error);
		return null;
	}
};

function ReportDataGridTodayVisitorsSendEmail() {
	const [data, setData] = useState([]);

	useEffect(() => {
		const getUsers = async () => {
			const fetchedData = await fetchUserData();
			if (fetchedData) {
				setData(fetchedData);
			}
		};

		getUsers();
	}, []);

	const sendEmail = async (emailData) => {
		try {
			const response = await fetch(urlSendMail, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(emailData),
			});
			if (response.ok) {
				const responseData = await response.json();
				alert("Email sent successfully: ", responseData.message);
			} else {
				throw new Error("Failed to send email");
			}
			console.log("Email sent successfully:", response.data.message);
		} catch (error) {
			console.error("Failed to send email:", error.response.data);
		}
	};



	const handleSendEmail = (date) => {
		const dateConverted = new Date(date);
		const emailDate = formatDateToYYYYMMDD(dateConverted);

		const emailData = {
			weekDate: emailDate,
		};
		sendEmail(emailData);
	};

	return (
		<div style={{ width: "80%" }}>
			<div style={{ height: 305
				, width: "100%" }}>
				<Box
					sx={{
						mt: 10,
						ml: 2,
						height: "550px",
						overflow: "auto",
						"& .actions": {
							color: "text.secondary",
						},
						"& .textPrimary": {
							color: "text.primary",
						},
					}}
				>
					<div>
						<DataGridWeekVisitorsReports
							dataSet={data}
							handleSendEmail={handleSendEmail}

						/>
					</div>
				</Box>
			</div>
		</div>
	);
}

export default ReportDataGridTodayVisitorsSendEmail;
