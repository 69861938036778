import React, { useEffect, useState } from "react";
import {
	DataGrid,
	GridToolbarQuickFilter,
	GridLogicOperator,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import { darken, lighten, styled } from "@mui/material/styles";
import PersonAdd from "@mui/icons-material/PersonAdd";
import EmergencyAllocationModal from "../Modals/EmergencyAllocationModal";
import SnackBarComponent from "../snackbar/SnackBarComponent";

const initialValues = {
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	address_line_1: "",
	postal_code: "",
	visiting_cycle_id: "",
	dependant_count: "",
	adults_count: "",
	children_count: "",
};

function QuickSearchToolbar() {
	return (
		<Box
			sx={{
				p: 2,
			}}
		>
			<GridToolbarQuickFilter
				quickFilterParser={(searchInput) =>
					searchInput
						.split(",")
						.map((value) => value.trim())
						.filter((value) => value !== "")
				}
			/>
		</Box>
	);
}

const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .super-app-theme--regular-allocation": {
		backgroundColor: getBackgroundColor(
			theme.palette.primary.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.primary.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.primary.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.primary.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--emergency-allocation": {
		backgroundColor: getBackgroundColor(
			theme.palette.error.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.error.main,
					theme.palette.mode
				),
			},
		},
	},
}));

export default function QuickFilteringCustomizedGrid() {
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const url = "/api/visiting-users";
	const [tableData, setTableData] = useState([]);
	const [disableButton, setDisableButton] = useState(true);
	const [comments, setComments] = useState("");
	const [open, setOpen] = useState(false);
	const [checkedRowSelectionModel, setCheckedRowSelectionModel] = useState([]);
	const [openNewUser, setOpenNewUser] = useState(false);
	const [newUser, setNewUser] = useState(initialValues);
	const [severityAlert, setSeverityAlert] = useState("success");
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [message, setMessage] = useState("");
	const [newUserError, setNewUserError] = useState("");

	const handleSeverityChange = () => setSeverityAlert("error");

	const getUsers = () => {
		fetch(url)
			.then((resp) => resp.json())
			.then((resp) => setTableData(resp));
	};

	useEffect(() => {
		getUsers();
	}, [openSnackBar]);
	//Registering visitors regular or emergency logs
	function handleSubmit() {
		let newMessageRequest = {
			ids: rowSelectionModel,
		};

		if (comments) {
			newMessageRequest = {
				ids: [
					{
						...newMessageRequest.ids[0],
						comments: comments,
						allocation: "emergency",
					},
				],
			};
		} else {
			newMessageRequest = {
				ids: newMessageRequest.ids.map((member) => ({
					...member,
					allocation: "regular",
					comments: "",
				})),
			};
		}

		fetch("/api/send-visiting-registration", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newMessageRequest),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(response);
				}
				handleOpenSnackBar();
				handleMessage("The visitor was logged correctly");
			})
			.catch((error) => {
				handleSeverityChange();
				handleOpenSnackBar();
				handleMessage(`There was an error ${error}`);
			});

		setComments("");
		setDisableButton(true);
		setCheckedRowSelectionModel([]);
		waitingAlertProcess();
	}

	const waitingAlertProcess = () => {
		setInterval(() => {
			handleCloseSnackBar();
			setSeverityAlert("success");
			setMessage("");
		}, 6000);
	};

	///Registering visitors
	const data = {
		columns: [
			{ field: "name", headerName: "Name", width: 135 },
			{ field: "household", headerName: "Household", width: 145 },
			{ field: "last_visit", headerName: "Last visit", width: 95 },
			{ field: "next_visit", headerName: "Next visit", width: 95 },
			{ field: "monthly_cycle", headerName: "Cycle", width: 80 },
			{ field: "address", headerName: "Address", width: 120 },
			{ field: "phone_number", headerName: "Phone number", width: 120 },
			{ field: "emergency_date", headerName: "Last Emergency", width: 120 },
			{
				field: "comments",
				headerName: "comments",
				width: 100,
				maxWidth: 400,
				minWidth: 350,
			},
		],
		rows: tableData,
		rowLength: 50,
	};

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleCloseSaveEmergency = () => {
		setOpen(false);
		handleSubmit();
	};
	const handleCancelClose = () => {
		setOpen(false);
		setComments("");
	};
	const handleChange = (e) => {
		setComments(e.target.value);
	};

	//Check date to compare and change the background
	const dateChecker = new Date().toLocaleString("en-GB", {
		day: "2-digit",
		month: "short",
		year: "2-digit",
	});

	const styledRowBackground = (params) => {
		return params.row.allocation === "regular"
      && params.row.last_visit === dateChecker
			? "regular-allocation"
			: params.row.allocation === "emergency" && params.row.emergency_date === dateChecker
				? "emergency-allocation"
				: "";
	};

	///New user functions
	const handleChangeInputNewUser = (e) => {
		const { name, value } = e.target;
		setNewUser({
			...newUser,
			[name]: value,
		});
		setNewUserError("");
	};

	const handleCloseNewUser = () => {
		setOpenNewUser(false);
	};

	const handleCloseAndSaveNewUser = () => {
		if (!newUser.children_count) {
			setNewUserError("Field cannot be empty");
			return;
		}
		handleSubmitNewUser();
		setOpenNewUser(false);
	};

	function handleSubmitNewUser() {
		const newMessageRequest = {
			ids: [newUser],
		};

		fetch("/api/create-new-visitor", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newMessageRequest),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error();
				}
				handleOpenSnackBar();
				handleMessage("The visitor was logged correctly");
			})
			.catch((error) => {
				handleSeverityChange();
				handleOpenSnackBar();
				handleMessage(`There was an error ${error}`);
			});
		setCheckedRowSelectionModel([]);
		setOpenNewUser(false);
		setNewUser(initialValues);
		waitingAlertProcess();
	}

	//Snackbar component
	const handleOpenSnackBar = () => setOpenSnackBar(true);
	const handleCloseSnackBar = () => setOpenSnackBar(false);
	const handleMessage = (e) => setMessage(e);

	return (
		<div style={{ width: "80%" }}>
			<div style={{ height: 350, width: "100%" }}>
				<Box
					sx={{
						mt: 10,
						ml: 2,
						height: "550px",
						overflow: "auto",
						"& .actions": {
							color: "text.secondary",
						},
						"& .textPrimary": {
							color: "text.primary",
						},
					}}
				>
					<SnackBarComponent
						openSnackBar={openSnackBar}
						message={message}
						severity={severityAlert}
					/>
					{/* // */}

					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							p: 1,
						}}
					>
						<Button
							variant="contained"
							endIcon={<SendIcon />}
							onClick={handleSubmit}
							disabled={disableButton}
							sx={{ ml: -1 }}
						>
            SND
						</Button>
						<>
							<Button
								variant="contained"
								endIcon={<SendIcon />}
								onClick={handleClickOpen}
								disabled={disableButton}
								color="error"
								sx={{ ml: 1 }}
							>
              EXTRA
							</Button>

							<EmergencyAllocationModal
								open={open}
								handleCloseSaveEmergency={handleCloseSaveEmergency}
								comments={comments}
								handleChange={handleChange}
								handleCancelClose={handleCancelClose}
							/>

							<Button
								variant="contained"
								onClick={() => setOpenNewUser(true)}
								sx={{ ml: 1 }}
							>
								<PersonAdd />
							</Button>
							<>
								<Dialog open={openNewUser}>
									<DialogTitle>Add new visitor</DialogTitle>
									<DialogContent>
										<>
											<Grid
												container
												spacing={3}
												sx={{
													"& .actions": {
														color: "text.secondary",
													},
													"& .textPrimary": {
														color: "text.primary",
													},
												}}
											>
												<Grid item xs={12} sm={6}>
													<TextField
														required
														id="firstName"
														name="first_name"
														label="First name"
														fullWidth
														autoComplete="given-name"
														variant="outlined"
														value={newUser.first_name}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														required
														id="lastName"
														name="last_name"
														label="Last name"
														fullWidth
														autoComplete="family-name"
														variant="outlined"
														value={newUser.last_name}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														required
														id="phoneNumber"
														name="phone_number"
														label="Phone number"
														fullWidth
														autoComplete="Mobile number"
														variant="outlined"
														value={newUser.phone_number}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														id="email"
														name="email"
														label="Email"
														type="email"
														fullWidth
														autoComplete="email"
														variant="outlined"
														value={newUser.email}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														required
														id="postCode"
														name="postal_code"
														label="Postal code"
														type="text"
														fullWidth
														autoComplete="post code"
														variant="outlined"
														value={newUser.postal_code}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														required
														id="address"
														name="address_line_1"
														label="Address"
														type="text"
														fullWidth
														autoComplete="Address"
														variant="outlined"
														value={newUser.address_line_1}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														required
														id="visiting cycle"
														name="visiting_cycle_id"
														label="visiting_cycle"
														type="number"
														fullWidth
														autoComplete="visiting cycle"
														variant="outlined"
														value={newUser.visiting_cycle_id}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														required
														id="dependant count"
														name="dependant_count"
														label="Dependant count"
														type="number"
														fullWidth
														autoComplete="Household"
														variant="outlined"
														value={newUser.dependant_count}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														required
														id="adults count"
														name="adults_count"
														label="Adults count"
														type="number"
														fullWidth
														autoComplete="Adults household"
														variant="outlined"
														value={newUser.adults_count}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
												<Grid item xs={12} sm={4}>
													<TextField
														required
														error={!!newUserError}
														helperText={newUserError}
														id="children count"
														name="children_count"
														label="Children count"
														type="number"
														fullWidth
														autoComplete="Children household"
														variant="outlined"
														value={newUser.children_count}
														onChange={handleChangeInputNewUser}
													/>
												</Grid>
											</Grid>
										</>
										<DialogActions>
											<Button onClick={handleCloseNewUser}>Cancel</Button>
											<Button type="submit" onClick={handleCloseAndSaveNewUser}>Save</Button>
										</DialogActions>
									</DialogContent>
								</Dialog>
							</>
						</>
					</Box>

					{/* // */}
					<StyledDataGrid
						{...data}
						getRowId={(row) => row.user_id}
						getRowClassName={(params) =>
							`super-app-theme--${styledRowBackground(params)}`
						}
						checkboxSelection
						columns={data.columns}
						initialState={{
							...data.rows.initialState,
							filter: {
								...data.rows.initialState?.filter,
								filterModel: {
									items: [],
									quickFilterLogicOperator: GridLogicOperator.Or,
								},
							},
							...data.rowLength.initialState,
							pagination: {
								paginationModel: { pageSize: 50, page: 0 },
							},
							...data.initialState?.columns,
							columnVisibilityModel: {
								user_id: false,
							},
							...data.initialState?.columns,
							sorting: {
								sortModel: [{ field: "name", sort: "asc" }],
							},
						}}
						slots={{ toolbar: QuickSearchToolbar }}
						disableRowSelectionOnClick
						onRowSelectionModelChange={(newRowSelected) => {
							setRowSelectionModel(
								data.rows.filter((value) =>
									newRowSelected.includes(value.user_id)
								)
							);
							setCheckedRowSelectionModel(newRowSelected);
							setDisableButton(false);
						}}
						rowSelectionModel={checkedRowSelectionModel}
						disableColumnFilter
						disableColumnSelector
						disableDensitySelector
					/>
				</Box>
			</div>
		</div>
	);
}
