import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { NavLink } from "react-router-dom";

export const mainListItems = (
	// const [open, setOpen] = React.useState(true);

	// const handleClick = () => {
	//   setOpen(!open);
	// };


	<>
		<NavLink to="/" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItemButton>
		</NavLink>

		<NavLink to="/user-visitors" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<ShoppingCartIcon />
				</ListItemIcon>
				<ListItemText primary="Visitors" />
			</ListItemButton>
		</NavLink>

		<NavLink to="/user-database" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<PeopleIcon />
				</ListItemIcon>
				<ListItemText primary="User Database" />
			</ListItemButton>
		</NavLink>

		{/* <NavLink to="/user-visitors-test" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary="Test Edition" />
			</ListItemButton>
		</NavLink> */}

		{/* <ListItemButton>
			<ListItemIcon>
				<LayersIcon />
			</ListItemIcon>
			<ListItemText primary="Integrations" />
		</ListItemButton> */}
	</>
);

export const secondaryListItems = (
	<>
		<ListSubheader component="div" inset>
      Saved reports
		</ListSubheader>

		<NavLink to="/weekly-visitors" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary="Weekly Reports" />
			</ListItemButton>
		</NavLink>

		<NavLink to="/today-visitors" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Today Visitors Reports" />
			</ListItemButton>
		</NavLink>

		{/* <NavLink to="/today-visitors-test" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Report" />
			</ListItemButton>
		</NavLink>

		<NavLink to="/today-visitors-test-out" style={{ textDecoration: "none" }}>
			<ListItemButton>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Report" />
			</ListItemButton>
		</NavLink> */}
	</>
);
