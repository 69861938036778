import React, { useState } from "react";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { ExportButton } from "../excel-export/week-report/ExportButton";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";

function CustomToolbar(props) {
	return (
		<GridToolbarContainer {...props}>
			<ExportButton />
		</GridToolbarContainer>
	);
}

const options = { year: "numeric", month: "short", day: "numeric" };

export default function DataGridWeekVisitorsReports(props) {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [isOpen, setIsOpen] = useState(false);
	const [activateSend, setActivateSend] = useState(false);

	const handleClick = (e) => {
		e.preventDefault();
		setIsOpen(!isOpen);
	};
	const handleDateChange = (date) => {
		setIsOpen(!isOpen);
		setSelectedDate(date);
		setActivateSend(!activateSend);
	};

	const data = {
		columns: [
			{ field: "user_id", headerName: "User Id", width: 65 },
			{ field: "name", headerName: "Name", width: 170 },
			{ field: "household", headerName: "Household", width: 220 },
		],
		rows: props.dataSet,
		rowLength: 50,
	};

	const isWeekday = (date) => {
		const day = date.getDay();
		return (
			day !== 0 && day !== 1 && day !== 2 && day !== 3 && day !== 5 && day !== 6
		);
	};

	return (
		<>
			<DataGrid
				{...data}
				getRowId={(row) => row.user_id}
				columns={data.columns}
				initialState={{
					...data.rowLength.initialState,
					pagination: {
						paginationModel: { pageSize: 6, page: 0 },
					},
				}}
				row={data.rows}
				density="standard"
				slots={{ toolbar: CustomToolbar }}
			/>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
				}}
			>
				<Button sx={{ mt: 1, mb: 1 }} variant="contained" onClick={handleClick}>
					{selectedDate
						? selectedDate.toLocaleDateString("en-GB", options)
						: "Date"}

					<CalendarMonthIcon style={{ marginLeft: "0.5rem" }} />
				</Button>

				{isOpen && (
					<DatePicker
						selected={selectedDate}
						onChange={handleDateChange}
						filterDate={isWeekday}
						placeholderText="Select Thursday"
						dateFormat="dd/MM/yyyy"
						inline
					></DatePicker>
				)}

				<Button
					sx={{ ml: 1, mt: 1, mb: 1 }}
					variant="contained"
					onClick={() => props.handleSendEmail(selectedDate)}
					disabled={!activateSend}
				>
          Send
					<SendIcon style={{ marginLeft: "0.5rem" }} />
				</Button>
			</Box>
		</>
	);
}