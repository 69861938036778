import React from "react";
import { Navigate } from "react-router-dom";

export function PublicElement ({ children, currentUser }) {
	if (currentUser === "Public User") {
		return <>{children}</>;
	} else {
		return <Navigate to="/do-not-have-permission" />;
	}
}

export function AssistantElement ({ children, currentUser }) {
	if (currentUser === "Assistant User" || currentUser === "Admin User" ) {
		return <>{children}</>;
	} else {
		return <Navigate to="/do-not-have-permission" />;
	}
}

export function AdminElement ({ children, currentUser }) {
	if ( currentUser === "Admin User" ){
		return <>{children}</>;
	} else {
		return <Navigate to="/do-not-have-permission" />;
	}
}

export default (PublicElement, AssistantElement, AdminElement);

