import * as React from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function SnackBarComponent(props) {

	return (
		<Box sx={{ width: 500 }}>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal:"center" }}
				open={props.openSnackBar}
			>
				<Alert severity={props.severity} variant="filled" sx={{ width: "100%" }}>
					{props.message}
				</Alert>
			</Snackbar>

		</Box>
	);
}
