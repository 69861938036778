import Box from "@mui/material/Box";
import Dashboard  from "./dashboard/Dashboard";
import Charts from "../components/chart/Charts";

export function Home() {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Dashboard />
				<Charts />
			</Box>
		</>
	);
}

export default Home;
